.App {
  height: 100vh;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; */
}

.coming-soon {

  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .coming-soon {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  height: 90%;
}

footer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10%;
}

footer a.email {

  color: black;
  text-decoration:none;
}


@keyframes floating {

  0% { transform: translate(0,  0px); }
  50%  { transform: translate(0, 15px); }
  100%   { transform: translate(0, -0px); }
}
